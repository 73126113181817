import Check from '../../../assets/check.svg';

function CarouselItem(data, index) {
  const carouselItem = document.createElement('div');
  carouselItem.className = `carousel-item ${index === 0 && 'active'}`;

  const indicatorButton = document.createElement('button');
  indicatorButton.setAttribute('data-bs-target', '#carousel');
  if (index === 0) indicatorButton.setAttribute('class', 'active');
  indicatorButton.setAttribute('data-bs-slide-to', index);
  indicatorButton.setAttribute('aria-current', 'true');
  indicatorButton.setAttribute('aria-label', `Slide ${index + 1}`);

  document.querySelector('.carousel-indicators').appendChild(indicatorButton);

  const img = document.createElement('img');
  img.src = data.img;
  img.alt = '-image';

  const groupDiv = document.createElement('div');

  const heading = document.createElement('h2');
  heading.textContent = data.heading;

  const description = document.createElement('p');
  description.textContent = data.description;

  const features = document.createElement('ul');

  data.features.forEach((f) => {
    const feature = document.createElement('li');
    const span = document.createElement('span');
    const checkIcon = document.createElement('img');
    span.textContent = f;

    checkIcon.src = Check;
    checkIcon.alt = 'check-icon';

    feature.style.display = 'flex';
    feature.style.flexDirection = 'row-reverse';
    feature.style.alignItems = 'flex-start';
    feature.style.justifyContent = 'flex-end';
    feature.appendChild(span);

    if (f !== 'جهت کسب اطلاعات بیشتر با شماره 33357808-041 تماس حاصل نمایید.')
      feature.appendChild(checkIcon);
    features.appendChild(feature);
  });

  const button = document.createElement('button');
  button.textContent = 'مشاهده';

  button.className = 'btn btn-primary';

  button.addEventListener('click', (e) => {
    e.preventDefault();
    const url = window.location.toString().split('/pages')[0];
    window.location = url + '/' + data.link;
  });

  groupDiv.appendChild(heading);
  groupDiv.appendChild(description);
  groupDiv.appendChild(features);
  groupDiv.appendChild(button);

  carouselItem.appendChild(img);
  carouselItem.appendChild(groupDiv);

  document.querySelector('.carousel-inner').appendChild(carouselItem);

  return carouselItem.outerHTML;
}

export default CarouselItem;
