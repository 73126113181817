import CertificationLogo from '../../../assets/certification.svg';

function CertificationItem(data) {
  const div = document.createElement('div');
  div.className = 'certification-item';
  div.setAttribute('data-aos', 'zoom-out');

  const img = document.createElement('img');
  img.src = CertificationLogo;
  img.alt = data.title;

  const span = document.createElement('span');
  span.textContent = data.title;

  div.appendChild(img);
  div.appendChild(span);

  div.addEventListener('click', () => {
    window.open(data.link, 'mywindow');
  });

  document.querySelector('#certification-container').appendChild(div);
  return div.outerHTML;
}

export default CertificationItem;
