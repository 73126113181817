import Shafadoc from '../../../assets/shafadoc.jpg';
import CarouselItem from '../../components/home/CarouselItem';
import ServiceItem from '../../components/home/ServiceItem';
import CustomerItem from '../../components/home/CustomerItem';
import ExperienceItem from '../../components/home/ExperienceItem';
import CertificationItem from '../../components/home/CertificationItem';
import EmployeeItem from '../../components/home/EmployeeItem';
import carouselData from '../../../data/carousel.json';
import serviceData from '../../../data/services.json';
import customerData from '../../../data/customers.json';
import certificationData from '../../../data/certifications.json';
import experienceData from '../../../data/experiences.json';
import employeeData from '../../../data/employees.json';
import HealthLogo from '../../../assets/health.jpg';
import { Carousel } from 'bootstrap';
import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import L from 'leaflet';

class HomePage {
  constructor() {
    function importAll(r) {
      return r.keys().map(r);
    }

    // carousel

    const carouselImages = importAll(
      require.context('../../../assets/carousel', false, /\.(png|jpe?g)$/)
    );

    const splitedCarouselImages = carouselImages.map(
      (img) => img.split('assets/')[1]
    );

    carouselData.forEach((item, index) => {
      const { img } = item;
      const url = carouselImages[0].split('assets/')[0] + 'assets/';
      let image;

      image = splitedCarouselImages.find((image) => image === img);

      const newData = {
        ...item,
        img: url + image,
      };

      CarouselItem(newData, index);
    });

    new Carousel('#carousel', {
      ride: 'carousel',
      interval: 2000,
      wrap: true,
    });

    //end of carousel

    // about us

    document.querySelector('#shafadoc-img').src = Shafadoc;

    // end of about us

    // service
    const serviceImages = importAll(
      require.context('../../../assets/services', false, /\.(png|jpe?g)$/)
    );

    const splitedServiceImages = serviceImages.map(
      (img) => img.split('assets/')[1]
    );

    serviceData.forEach((item, index) => {
      const { img, img_hover } = item;
      const url = serviceImages[0].split('assets/')[0] + 'assets/';
      let image;
      let hover_image;

      image = splitedServiceImages.find((image) => image === img);
      hover_image = splitedServiceImages.find((image) => image === img_hover);

      const newData = {
        ...item,
        img: url + image,
        img_hover: url + hover_image,
      };

      ServiceItem(newData, index);
    });

    // end of service

    // customer
    const customerImages = importAll(
      require.context('../../../assets/customers', false, /\.(png|jpe?g)$/)
    );

    const splitedCustomerImages = customerImages.map(
      (img) => img.split('assets/')[1]
    );

    customerData.forEach((item) => {
      const { img } = item;
      const url = customerImages[0].split('assets/')[0] + 'assets/';
      let image;

      image = splitedCustomerImages.find((image) => image === img);

      const newData = {
        ...item,
        img: url + image,
      };

      CustomerItem(newData);
    });

    new Swiper('#customer-container', {
      modules: [Navigation, Pagination, Autoplay],
      speed: 400,
      freeMode: true,
      cssMode: true,
      slidesPerView: 4,
      slidesPerGroup: 4,
      breakpoints: {
        200: {
          slidesPerView: 1,
          slidesPerGroup: 1,
          spaceBetween: 40,
        },
        550: {
          slidesPerView: 2,
          slidesPerGroup: 2,
          spaceBetween: 40,
        },

        768: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 40,
        },

        1024: {
          slidesPerView: 4,
          slidesPerGroup: 4,
          spaceBetween: 40,
        },
      },
      loop: true,
      autoplay: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
    //end of customer

    // experience
    const experienceContainer = document.querySelector('.experience-container');
    experienceContainer.style.backgroundImage = `url(${HealthLogo})`;

    const experienceImages = importAll(
      require.context('../../../assets/experiences', false, /\.(svg)$/)
    );

    const splitedExperienceImages = experienceImages.map(
      (img) => img.split('assets/')[1]
    );

    experienceData.forEach((item) => {
      const { img } = item;
      const url = experienceImages[0].split('assets/')[0] + 'assets/';
      let image;

      image = splitedExperienceImages.find((image) => image === img);

      const newData = {
        ...item,
        img: url + image,
      };

      ExperienceItem(newData);
    });
    // end of experience

    // certifications

    const certificationFiles = importAll(
      require.context(
        '../../../assets/certifications',
        false,
        /\.(png|jpe?g|pdf)$/
      )
    );

    const splitedCertificationFiles = certificationFiles.map((img) => {
      return img.split('assets/')[1];
    });

    certificationData.forEach((item) => {
      const { link } = item;
      const url = certificationFiles[0].split('assets/')[0] + 'assets/';

      let newData;
      let file;

      file = splitedCertificationFiles.find((f) => {
        if (f === link) {
          return f;
        }
      });

      if (
        link.split('.').pop() === 'jpg' ||
        link.split('.').pop() === 'jpeg' ||
        link.split('.').pop() === 'png' ||
        link.split('.').pop() === 'gif' ||
        link.split('.').pop() === 'pdf'
      ) {
        newData = {
          ...item,
          link: url + file,
        };
      } else {
        newData = {
          ...item,
          link: link,
        };
      }

      CertificationItem(newData);
    });
    // end of certifications

    // employees

    const employeeImages = importAll(
      require.context('../../../assets/employees', false, /\.(png|jpe?g)$/)
    );

    const splitedEmployeeImages = employeeImages.map(
      (img) => img.split('assets/')[1]
    );

    employeeData.forEach((item) => {
      const { avatar } = item;
      const url = employeeImages[0].split('assets/')[0] + 'assets/';
      let image;

      image = splitedEmployeeImages.find((image) => image === avatar);

      const newData = {
        ...item,
        avatar: url + image,
      };

      EmployeeItem(newData);
    });

    // end of employees

    // contact us

    const mapAddress = document.querySelector('.contact-map');

    const map = L.map(mapAddress).setView(
      [38.070392772702, 46.321125545512],
      16
    );

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);

    L.marker([38.070392772702, 46.321125545512]).addTo(map).openPopup();

    // end of contact us
  }
}

new HomePage();

export default HomePage;
